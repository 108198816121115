import * as React from "react";
import "./styles/kindle.css";
export default class KindlePage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div>
          <h1>キンドル本の紹介</h1>
          <p className="generic">
            趣味の体験や経験をキンドル本にして皆様にお届けしています。
          </p>
          <div className="kindle_book">
            <div className="card text-white bg-secondary mb-3">
              <div className="card-header">ハイテク登山術</div>
              <div className="image_div">
                <a href="https://www.amazon.co.jp/%E3%83%8F%E3%82%A4%E3%83%86%E3%82%AF%E7%99%BB%E5%B1%B1%E8%A1%93-%E5%BF%83%E6%8B%8D%E8%A8%88%E3%82%92%E4%BD%BF%E3%81%A3%E3%81%9F%E3%83%9A%E3%83%BC%E3%82%B9%E7%AE%A1%E7%90%86-%E5%B9%B3%E4%BA%95-%E6%99%BA%E5%89%87-ebook/dp/B06XZ1QP9K">
                  <img src="images/kindle_high_tech_hike.jpg" />
                </a>
              </div>
              <div className="card-body">
                <h4 className="card-title">快適な登山をあなたに！</h4>
                <p className="card-text">
                  ランニングの世界ではよく活用されている、
                  心拍数をもとにした、ペース管理の方法を登山・ハイキングでも取り入れて、
                  歩行ペースの管理を行う方法を紹介しています。
                </p>
              </div>
            </div>
            <div className="card text-white bg-secondary mb-3">
              <div className="card-header">カリフォルニアの最高峰を登る！</div>
              <div className="image_div">
                <a href="https://www.amazon.co.jp/%E3%82%AB%E3%83%AA%E3%83%95%E3%82%A9%E3%83%AB%E3%83%8B%E3%82%A2%E3%81%AE%E6%9C%80%E9%AB%98%E5%B3%B0%E3%82%92%E7%99%BB%E3%82%8B%EF%BC%81-%E4%BA%BA%E6%B0%97%E3%81%AE%E3%83%8F%E3%82%A4%E3%82%AD%E3%83%B3%E3%82%B0%E3%83%88%E3%83%AC%E3%83%BC%E3%83%AB%E3%80%81%E3%83%9E%E3%82%A6%E3%83%B3%E3%83%88%E3%83%BB%E3%83%9B%E3%82%A4%E3%83%83%E3%83%88%E3%83%8B%E3%83%BC%E3%83%BB%E3%83%88%E3%83%AC%E3%83%BC%E3%83%AB%E3%82%92%E5%86%99%E7%9C%9F%E3%81%A8%E5%85%B1%E3%81%AB%EF%BC%81-%E5%B9%B3%E4%BA%95-%E6%99%BA%E5%89%87-ebook/dp/B01G3H75GU/ref=sr_1_3?qid=1566847864&s=digital-text&sr=1-3&text=%E5%B9%B3%E4%BA%95%E3%80%80%E6%99%BA%E5%89%87">
                  <img src="images/kindle_whitney.jpg" />
                </a>
              </div>
              <div className="card-body">
                <h4 className="card-title">手軽に登れる4000m峰</h4>
                <p className="card-text">
                  手軽に登れる4000m峰である、カリフォルニア州最高峰のMt.
                  Whitneyの登山ガイドです。
                  計画の立て方から、許可の取り方までMt.Whitneyに登るのに必要な情報を満載しています。
                </p>
              </div>
            </div>
            <div className="card text-white bg-secondary mb-3">
              <div className="card-header">日本酒醸造プロジェクト！</div>
              <div className="image_div">
                <a href="https://www.amazon.co.jp/%E6%97%A5%E6%9C%AC%E9%85%92%E9%86%B8%E9%80%A0%E3%83%97%E3%83%AD%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88-%E3%82%AB%E3%83%AA%E3%83%95%E3%82%A9%E3%83%AB%E3%83%8B%E3%82%A2%E3%81%A7%E3%81%AE%E6%97%A5%E6%9C%AC%E9%85%92%E4%BD%9C%E3%82%8A%E3%81%AE%E4%BD%93%E9%A8%93-%E5%B9%B3%E4%BA%95-%E6%99%BA%E5%89%87-ebook/dp/B077Q58T24/ref=sr_1_2?qid=1566847864&s=digital-text&sr=1-2&text=%E5%B9%B3%E4%BA%95%E3%80%80%E6%99%BA%E5%89%87">
                  <img src="images/kindle_sake.jpg" />
                </a>
              </div>
              <div className="card-body">
                <h4 className="card-title">お酒造りの体験！</h4>
                <p className="card-text">
                  日本では規制されている日本酒作りですが、カリフォルニア州では合法的に作る事ができます。
                  家庭で美味しい日本酒を作る方法を求めて数年にわたる日本酒醸造の記録です。
                </p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
