import React from "react";
import * as TYPE from "../lib/types";
interface IProps {
  name: string;
  url: string;
  items: Array<TYPE.NavItem>;
}
interface IState {}
export default class Navbar extends React.Component<IProps, IState> {
  render() {
    return (
      <React.Fragment>
        <div className="nav_bar">
          <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
            <a className="navbar-brand" href={this.props.url}>
              {this.props.name}
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto">
                {this.props.items.map((item: TYPE.NavItem) => (
                  <li className="nav-item" key={item.name}>
                    <a className="nav-link" href={item.link}>
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
        </div>
      </React.Fragment>
    );
  }
}
