import React from "react";
import Blog from "../lib/blog";
import Header from "../components/header";
import PostList from "../components/postlist";
import * as CONSTANT from "../lib/constants";
import * as TYPE from "../lib/types";

import "./styles/home.css";
interface IProps {}
interface IState {
  posts: Array<TYPE.StorageFile>;
}
export default class Home extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      posts: [],
    };
    this.init();
  }
  init() {
    console.log("init");
    Blog.getPosts(CONSTANT.FB_BLOG_FOLDER).then((posts) => {
      this.setState({
        posts: posts,
      });
    });
  }
  render() {
    return (
      <React.Fragment>
        <div className="home_page">
          <Header article={false} />
          <div className="post_list">
            <PostList posts={this.state.posts} maxPosts={5} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
