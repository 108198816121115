import marked from "marked";
import matter from "gray-matter";
import firebase, { getDocuments } from "./firebase";
import * as CONSTANT from "./constants";
import * as TYPE from "./types";
class Blog {
  /** Gets a folder by the folder name
   * @param name A folder name to look for (string)
   * @return A folder object (TYPE.FbFolder) if it exists, otherwise returns undefined
   */
  static getFolder(name: string): Promise<TYPE.FbFolder | undefined> {
    return new Promise((resolve) => {
      firebase
        .firestore()
        .collection(CONSTANT.FB_COLLECTION_FOLDER)
        .where("name", "==", name)
        .get()
        .then((querySnapshot: firebase.firestore.QuerySnapshot) => {
          if (querySnapshot.size === 1) {
            // Exists
            const doc = querySnapshot.docs[0];
            const folder: TYPE.FbFolder = {
              id: doc.id,
              name: doc.data().name,
              display: doc.data().display,
            };
            resolve(folder);
          } else {
            // Error: It does not exists or moultiple entries are found
            resolve(undefined);
          }
        })
        .catch((error: any) => {
          if (CONSTANT.DEBUG) {
            console.log("lib/blog.ts:getFolder():");
            console.log(error);
          }
          resolve(undefined);
        });
    });
  }
  /** Gets all folders from Firebase Cloud Firstore
   * @return Returns a list of folder objects (Array<TYPE.FbFolder>)
   */
  static getFolders(): Promise<Array<TYPE.FbFolder>> {
    return new Promise((resolve) => {
      firebase
        .firestore()
        .collection(CONSTANT.FB_COLLECTION_FOLDER)
        .get()
        .then((querySnapshot: firebase.firestore.QuerySnapshot) => {
          const folders: Array<TYPE.FbFolder> = [];
          for (let i = 0; i < querySnapshot.size; i++) {
            const folder = {
              id: querySnapshot.docs[i].data().id,
              name: querySnapshot.docs[i].data().name,
              display: querySnapshot.docs[i].data().display,
            };
            folders.push(folder);
          }
          resolve(folders);
        })
        .catch((error: any) => {
          if (CONSTANT.DEBUG) {
            console.log("lib/blog.ts:getFolders():");
            console.log(error);
          }
          resolve([]);
        });
    });
  }
  /** Gets a post data
   * @param folderName A folder name of a post (string)
   * @param fileName A file name of a post (string)
   */
  static getPost(
    folderName: string,
    fileName: string
  ): Promise<TYPE.StorageFile | undefined> {
    return new Promise((resolve) => {
      Blog.getFolder(folderName).then((folder: TYPE.FbFolder | undefined) => {
        if (folder) {
          firebase
            .firestore()
            .collection(CONSTANT.FB_COLLECTION_DATA)
            .doc(folder.id)
            .collection(folderName)
            .where("file", "==", fileName + ".md")
            .get()
            .then((querySnapshot: firebase.firestore.QuerySnapshot) => {
              if (querySnapshot.size === 1) {
                const doc = querySnapshot.docs[0];
                const post: TYPE.StorageFile = {
                  id: doc.id,
                  folder: doc.data().folder,
                  file: doc.data().file,
                  title: doc.data().title,
                  description: doc.data().description,
                  category: doc.data().category,
                  type: doc.data().type,
                  unitAmount: doc.data().unitAmount,
                  priority: doc.data().priority,
                  timestamp: doc.data().timestamp,
                  url: doc.data().url,
                  contents: doc.data().contents,
                  selected: doc.data().selected,
                };
                Blog.getURL(folderName, post.file)
                  .then((url) => {
                    post.url = url;
                    if (url) {
                      fetch(url)
                        .then((response) => {
                          return response.text();
                        })
                        .then((text) => {
                          const matterResult = matter(text);
                          let contents: string = matterResult.content;
                          const html = marked(contents);
                          post.contents = html;
                          resolve(post);
                        })
                        .catch((err) => {
                          resolve(undefined);
                        });
                    } else {
                      resolve(undefined);
                    }
                  })
                  .catch((error: any) => {
                    resolve(undefined);
                  });
                // A document exists
              }
            })
            .catch((error: any) => {
              resolve(undefined);
            });
        } else {
          resolve(undefined);
        }
      });
    });
  }
  /** Gets all posts from a folder
   * @param folderName A folder name to get posts (string)
   * @return A list of posts (Array<TYPE.StorageFile>)
   */
  static getPosts(folderName: string): Promise<Array<TYPE.StorageFile>> {
    return new Promise((resolve) => {
      console.log(CONSTANT.DEBUG);
      Blog.getFolder(folderName).then((folder: TYPE.FbFolder | undefined) => {
        if (folder) {
          if (CONSTANT.DEBUG) {
            console.log(folder);
          }
          const ref: firebase.firestore.CollectionReference = firebase
            .firestore()
            .collection(CONSTANT.FB_COLLECTION_DATA)
            .doc(folder.id)
            .collection(folderName);
          getDocuments(ref)
            .then((docs: Array<firebase.firestore.DocumentData>) => {
              const posts: Array<TYPE.StorageFile> = [];
              for (let i = 0; i < docs.length; i++) {
                const doc = docs[i];
                const post: TYPE.StorageFile = {
                  id: doc.id,
                  folder: doc.data().folder,
                  file: doc.data().file,
                  title: doc.data().title,
                  description: doc.data().description,
                  category: doc.data().category,
                  type: doc.data().type,
                  unitAmount: doc.data().unitAmount,
                  priority: doc.data().priority,
                  timestamp: doc.data().timestamp,
                  url: doc.data().url,
                  contents: doc.data().contents,
                  selected: doc.data().selected,
                };
                posts.push(post);
              }
              resolve(posts);
            })
            .catch((error: any) => {
              if (CONSTANT.DEBUG) {
                console.log("lib/blog.ts:getPosts():");
                console.log(error);
              }
              resolve([]);
            });
        }
      });
    });
  }
  /** Gets a download URL for a file in Firebase storage
   *
   * @param folderName A folder name of a post (string)
   * @param fileName A file name of a post (string)
   * @returns A download URL if it exists, otherwise returns undefined
   */
  static getURL(
    folderName: string,
    fileName: string
  ): Promise<string | undefined> {
    return new Promise((resolve) => [
      firebase
        .storage()
        .ref()
        .child(folderName + "/" + fileName)
        .getDownloadURL()
        .then((url: string) => {
          resolve(url);
        })
        .catch((error: any) => {
          resolve(undefined);
        }),
    ]);
  }
}
export default Blog;
