import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import TagManager from "react-gtm-module";
import Ad from "./components/ad";
import Footer from "./components/footer";
import Navbar from "./components/navbar";
import Home from "./pages/home";
import KindlePage from "./pages/kindle"
import Article from "./pages/article"
import * as CONSTANT from "./lib/constants";
import * as TYPE from "./lib/types";
import "./App.css";
const NAV_ITEMS: Array<TYPE.NavItem> = [
  { name: "ホーム", link: "/" },
  {name:"GPS/心拍数解析サービス",link:"https://hr.tomonorihirai.com"},
  {name:"キンドル本",link:"/kindle"},
  {name:"旧ブログサイト",link:"https://old-climber.blogspot.com/"}
];
const tagManagerArgs = {
  gtmId: "GTM-5S5K9CF",
};
class App extends React.Component {
  constructor(props:any) {
    super(props)
    TagManager.initialize(tagManagerArgs);
  }
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Navbar
            name={CONSTANT.COPYRIGHT_NAME}
            url={CONSTANT.HOME_PAGE_URL}
            items={NAV_ITEMS}
          />
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/kindle" exact>
              <KindlePage />
            </Route>
            <Route path={window.location.pathname}>
              < Article path={window.location.pathname}/>
            </Route>
          </Switch>
          <Footer
            year={CONSTANT.COPYRIGHT_YEAR}
            name={CONSTANT.COPYRIGHT_NAME}
          />
        </BrowserRouter>
        <div>
          <Ad />
        </div>
      </div>
    );
  }
}

export default App;
