export const FB_BLOG_FOLDER = "outdoor";
export const FB_COLLECTION_FOLDER = "folders";
export const FB_COLLECTION_DATA = "data";
export const FB_COLLECTION_CATEGORY = "category";

export const DEBUG = process.env.REACT_APP_DEBUG === "true" ? true : false;
export const SITE_TITLE = process.env.REACT_APP_SITE_TITLE
  ? process.env.REACT_APP_SITE_TITLE
  : "アウトドアの情報サイト";
export const SITE_SUMMARY = process.env.REACT_APP_SITE_SUMMARY
  ? process.env.REACT_APP_SITE_SUMMARY
  : "";

export const HOME_PAGE_URL = process.env.REACT_APP_HOME_PAGE_URL
  ? process.env.REACT_APP_HOME_PAGE_URL
  : "https://tomonorihirai.com";

export const COPYRIGHT_NAME = process.env.REACT_APP_COPYRIGHT_NAME
  ? process.env.REACT_APP_COPYRIGHT_NAME
  : "Weekend Chronicole";
export const COPYRIGHT_YEAR = process.env.REACT_APP_COPYRIGHT_YEAR
  ? process.env.REACT_APP_COPYRIGHT_YEAR
  : "2020";
