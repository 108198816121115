import React from "react";
import * as TYPE from "../lib/types"
interface IProps {
  post:TYPE.StorageFile
}
interface IState {}
export default class PostForm extends React.Component<IProps, IState> {
  render() {
    return (
      <React.Fragment>
        <div key={this.props.post.id}>
          <h5><a href={this.props.post.folder + "/" + this.props.post.file.replace(/\.md$/,"")}>{this.props.post.title}</a></h5>
          <h6>{new Date(this.props.post.timestamp * 1000).toDateString()}</h6>
          <p>{this.props.post.description}</p>
        </div>
      </React.Fragment>
    );
  }
}
