import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
import * as CONSTANT from "./constants";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey ? process.env.REACT_APP_apiKey : "",
  authDomain: process.env.REACT_APP_authDomain
    ? process.env.REACT_APP_authDomain
    : "",
  databaseURL: process.env.REACT_APP_databaseURL
    ? process.env.REACT_APP_databaseURL
    : "",
  projectId: process.env.REACT_APP_projectId
    ? process.env.REACT_APP_projectId
    : "",
  storageBucket: process.env.REACT_APP_storageBucket
    ? process.env.REACT_APP_storageBucket
    : "",
  messagingSenderId: process.env.REACT_APP_messagingSenderId
    ? process.env.REACT_APP_messagingSenderId
    : "",
  appId: process.env.REACT_APP_appId ? process.env.REACT_APP_appId : "",
  measurementId: process.env.REACT_APP_measurementId
    ? process.env.REACT_APP_measurementId
    : "",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//firebase.analytics();
export default firebase;
/** Gets all documents from a collection in Firebase Cloud Firestore
 * @param reference A collection reference (firebase.firestore.CollectionReference)
 */
export function getDocuments(
  reference: firebase.firestore.CollectionReference
): Promise<Array<firebase.firestore.DocumentData>> {
  return new Promise((resolve) => {
    reference
      .orderBy("timestamp", "desc")
      .get()
      .then((queySnapshot: firebase.firestore.QuerySnapshot) => {
        const documents: Array<firebase.firestore.DocumentData> = [];
        for (let i = 0; i < queySnapshot.size; i++) {
          const document: firebase.firestore.DocumentData =
            queySnapshot.docs[i];
          documents.push(document);
        }
        resolve(documents);
      })
      .catch((error: any) => {
        if (CONSTANT.DEBUG) {
          console.log("lib/firebase.ts:getDocument():");
          console.log(error);
        }
        resolve([]);
      });
  });
}
