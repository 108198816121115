import React from "react";
import * as CONSTANT from "../lib/constants";
import "./styles/header.css";
interface IProps {
  article: boolean;
}
interface IState {}
export default class Header extends React.Component<IProps, IState> {
  render() {
    return (
      <React.Fragment>
        <div className="header">
          <div>
            <img src="/images/whitney.jpg" />
            <h1>{CONSTANT.SITE_TITLE}</h1>
            <p>{this.props.article ? "" : CONSTANT.SITE_SUMMARY}</p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
