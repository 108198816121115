import React from "react";
import PostForm from "./postform";
import * as TYPE from "../lib/types";
import "./styles/postlist.css";
import { timeStamp } from "console";
interface IProps {
  posts: Array<TYPE.StorageFile>;
  maxPosts: number;
}
interface IState {
  current: number;
}
export default class PostList extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      current: 0,
    };
  }
  nextPressed() {
    if (this.state.current + this.props.maxPosts < this.props.posts.length) {
      this.setState({
        current: this.state.current + this.props.maxPosts,
      });
    }
  }
  previousPressed() {
    if (this.state.current - this.props.maxPosts >= 0) {
      this.setState({
        current: this.state.current - this.props.maxPosts,
      });
    }
  }
  render() {
    const display = [];
    for (
      let i = this.state.current;
      i < this.state.current + this.props.maxPosts &&
      i < this.props.posts.length;
      i++
    ) {
      display.push(this.props.posts[i]);
    }
    let buttonDiv;
    if (this.state.current === 0) {
      buttonDiv = (
        <div className="post_list_button_div">
          <button
            className="btn btn-success"
            onClick={() => this.nextPressed()}
          >
            {"Next >"}
          </button>
        </div>
      );
    } else if (
      this.state.current + this.props.maxPosts >
      this.props.posts.length
    ) {
      buttonDiv = (
        <div className="post_list_button_div">
          <button
            className="btn btn-success"
            onClick={() => this.previousPressed()}
          >
            {"< Previous"}
          </button>
        </div>
      );
    } else {
      buttonDiv = (
        <div className="post_list_button_div">
          <button
            className="btn btn-success"
            onClick={() => this.previousPressed()}
          >
            {"< Previous"}
          </button>
          <button
            className="btn btn-success"
            onClick={() => this.nextPressed()}
          >
            {"Next >"}
          </button>
        </div>
      );
    }
    return (
      <React.Fragment>
        <h3>投稿</h3>
        <div className="post_list">
          {display.map((item: TYPE.StorageFile) => (
            <PostForm key={item.id} post={item} />
          ))}
        </div>
        {buttonDiv}
      </React.Fragment>
    );
  }
}
