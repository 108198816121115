import React from "react";
import Blog from "../lib/blog";
import Header from "../components/header";
import * as TYPE from "../lib/types";
import "./styles/article.css";
import "./styles/markdown.css";
interface IProps {
  path: string;
}
interface IState {
  post: TYPE.StorageFile | undefined;
}
export default class Article extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.init();
    this.state = {
      post: undefined,
    };
  }
  init() {
    const path = this.props.path.split("/");
    const folder = path[1];
    const file = path[2];
    Blog.getPost(folder, file).then((post: TYPE.StorageFile | undefined) => {
      this.setState({
        post: post,
      });
    });
  }
  render() {
    if (this.state.post) {
      return (
        <React.Fragment>
          <Header article={true} />
          <div className="article">
            <section>
              <article
                dangerouslySetInnerHTML={{
                  __html: this.state.post.contents
                    ? this.state.post.contents
                    : "",
                }}
              ></article>
            </section>
            <a href="/">{"<- トップページに戻る"}</a>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div></div>
        </React.Fragment>
      );
    }
  }
}
