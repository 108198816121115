import React from "react";
interface IProps {
  name: string;
  year: string;
}
interface IState {}
class Footer extends React.Component<IProps, IState> {
  render() {
    return (
      <div className="footer">
        <footer>
          Copyright(c) {this.props.year} by {this.props.name}, all rights
          reserved.
        </footer>
      </div>
    );
  }
}
export default Footer;
